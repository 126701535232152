import { MenuEntry } from '@mozartfinance/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://ucswap.org/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '#',
  },
  {
    label: 'Staking',
      icon: 'IfoIcon',
      href: 'https://staking.ucswap.org/',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '#',
  },
   {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: '#',
  },
   {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: '#',
  },
   {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: '#',
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: '#',
      },
      {
        label: 'Docs',
        href: 'https://ucswap.org/ucswapwhitepaper.pdf',
      },
      {
        label: 'Blog',
        href: '#',
      },
    ],
  },
 
]

export default config
